import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import './Home.css';
import Background from '../Images/Background.webp';
import Part_1 from '../Images/Part_1.webp';
import Part_2 from '../Images/Part_2.webp';
import Part_3 from '../Images/Part_3.webp';
import Part_4 from '../Images/Part_4.webp';
import Part_5 from '../Images/Part_5.webp';
import Part_6 from '../Images/Part_6.webp';
import Part_7 from '../Images/Part_7.webp';
import Part_8 from '../Images/Part_8.webp';
import Contact from '../Contact/Contact';
import Image_1 from '../Images/Image_1.webp';
import Image_2 from '../Images/Image_2.webp';
import Image_3 from '../Images/Image_3.webp';

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4, 
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        cssEase: 'ease',
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 1000, 
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false,
                    arrows: false
                }
            }
        ]
    };

    const partners = [
        Part_1,
        Part_2,
        Part_3,
        Part_4,
        Part_5,
        Part_6,
        Part_7,
        Part_8
    ];

    const sites = [
        { name: 'Corseaux', description: 'Bureau / Réception' },
        { name: 'St-Légier', description: 'Dépôt' },
        { name: 'Chailly', description: 'Succursale' }
    ];

    return (
        <div className='background-home'>
            <div className='container-home-1'>
                <img src={Background} alt="Background" />
                <div className='background-home-1'>
                    <div className='container-call'>
                        <h1>Assistance automobiles Riviera Sàrl</h1>
                        <p>Dépannage 24h/24 7j/7</p>
                        <div className='container-btn'>
                            <a href="tel:+41219437670">
                                <button>Appelez-nous</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-home-2'>
                <h2 className='title'>Nos emplacements</h2>
                {isMobile ? (
                    <Slider {...sliderSettings} className='container-box'>
                        {sites.map((site, index) => (
                            <div className='box' key={index}>
                                <div className='wrapper'>
                                    <h1>{site.name}</h1>
                                    <p>{site.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className='container-box'>
                        {sites.map((site, index) => (
                            <div className='box' key={index}>
                                <div className='wrapper'>
                                    <h1>{site.name}</h1>
                                    <p>{site.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className='container-home-3'>
                <h1>Nos différents partenaires</h1>
                <Slider {...sliderSettings} className='container-box'>
                    {partners.map((partner, index) => (
                        <div className='box' key={index}>
                            <div className='img-wrapper'>
                                <img src={partner} alt={`Partenaire ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className='container-home-4'>
                <div className='box'>
                    <h3>Atelier de Réparation Auto et<br />Vente de Pneus</h3>
                    <p>"Au sein de notre atelier, nous réalisons de petites réparations pour tous types de véhicules. ..."</p>
                </div>
                <div className='box'>
                    <h3>Services de Dépannage et<br />Transports Nationaux</h3>
                    <p>"Grâce à nos camions de dépannage équipés de plateaux, nous prenons en charge tous types de véhicules en panne, ..."</p>
                </div>
            </div>

            <Contact />

            <div className='container-home-5'>
                <h2>Quelques-unes de nos interventions</h2>
                <div className='container-box'>
                    <div className='box-1'>
                        <img src={Image_1} alt="Intervention 1" />
                    </div>
                    <div className='container-box-2'>
                        <div className='box-2'>
                            <img src={Image_2} alt="Intervention 2" />
                        </div>
                        <div className='box-3'>
                            <img src={Image_3} alt="Intervention 3" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;
