import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
    const navigate = useNavigate();

    const handleMentionsClick = () => {
        navigate('/mentions');
    };

    return (
        <footer>
            <div className='container-top'>
                <div className='box'>
                    <h1>Nos coordonnées</h1>
                    <span>Assistance automobiles Riviera Sàrl</span>
                    <span>Zone industrielle de Rio-Gredon 17,<br />1806 St-Légier-La Chiésaz</span>
                </div>
                <div className='box'>
                    <h1>Nos coordonnées</h1>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faPhone} />
                        <a href="tel:+41219437670" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>+41 21 943 76 70</span>
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faEnvelope} />
                        <a href="mailto:info@depannage-riviera.ch" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>info@depannage-riviera.ch</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className='container-center'>
                <h6 onClick={handleMentionsClick} style={{ cursor: 'pointer' }}>Mentions légales</h6>
                <h5>© 2024 tout droit réservé à Assistance automobiles Riviera Sàrl.</h5>
            </div>
            <div className='container-bottom'>
                Conçu par <a href="https://www.logixcube.ch/" style={{ textDecoration: 'none', color: '#ffffff' }} target="_blank" rel="noopener noreferrer">Logixcube.ch</a>
            </div>
        </footer>
    );
}

export default Footer;