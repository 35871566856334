import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './AAR/Home/Home';
import Legal from './AAR/Legal/Legal';
import Footer from './AAR/Footer/Footer';
import NoMatch from './AAR/Services/NoMatch';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mentions" element={<Legal />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
